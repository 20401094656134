<template>
  <div class="d-flex flex-column container-fluid">
    <h3>{{ $t('views.client.import.steps.step-1.general.subtitle') }}</h3>
    <d-table
      class="table"
      v-if="tableFields.length > 0"
      identifier="clients"
      :tablefields="tableFieldsFiltered"
      :items="items"
      :is-busy="false"
      :per-page="10"
      :totalRows="totalRows"
      :current-page="currentPage"
      @current-page:update="onCurrentPageUpdate"
    ></d-table>
  </div>
</template>
<script>
export default {
  data: () => ({
    currentPage: 1,
  }),
  props: {
    tableFields: {
      type: Object,
      default: []
    },
    items: {
      type: Object,
      default: []
    }
  },
  computed: {
    tableFieldsFiltered() {
      const fieldToShow = ['last_name', 'first_name', 'email', 'phone_number', 'birthday', 'city']
      return this.tableFields.filter(el => {
        return fieldToShow.findIndex(field => field === el.key) > -1;
      })
    },
    totalRows() {
      const length = this.items.length;
      return length; // length > 20 ? 20 : length
    }
  },
  methods: {
    onCurrentPageUpdate(page) {
      this.currentPage = page;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/client/import/_client-step1.scss";
</style>
